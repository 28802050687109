<template>
  <div class="SecurityInfo">
    <div id="PDFIframe" class="PDFIframe"></div>
    <div style="background-color:white;padding:20px;">
      <van-field
        label-width="150"
        error-message-align="right"
        input-align="right"
        v-model="FindFormApplyDetailData.FormApply.fmap03"
        name="表单名称"
        readonly
        colon
        label="表单名称"
        placeholder="请输入表单名称"
      />
      <van-form ref="submit" @submit="onSubmit">
        <div v-for="(item, index) in tempFields" :key="item.fafd01">
          <van-field
            input-align="right"
            v-if="item.fafd03 == 1"
            label-width="150"
            v-model="item.fafd06"
            :label="item.fafd05"
            colon
            name="fmfd06"
            placeholder="请选择输入内容"
          />
          <template v-if="item.fafd03 == 6">
            <van-cell :title="item.fafd05" :value="item.fafd06" />
            <!-- @click="timeShow = true; items = item" -->
          </template>
          <van-cell
            v-if="item.fafd03 == 2"
            :title="item.fafd05"
            :value="item.fafd06"
          />
          <!-- popupShow = true; -->
          <van-cell
            :title="item.fafd05"
            class="flexLR"
            v-if="item.fafd03 == 3 || item.fafd03 == 4 || item.fafd03 == 5"
          >
            <template slot="default">
              <div class="flexLR" style="justify-content: end;">
                <div class="flexLR" v-if="item.fafd03 != 5">
                  <span style="margin-right:8px;color:rgb(25, 137, 250)">{{
                    item.fafd06 != "" ? "已上传" : ""
                  }}</span>
                  <van-button
                    native-type="button"
                    size="mini"
                    icon="plus"
                    @click.stop="openUpdateFile(item.fafd01)"
                  >
                    上传文件
                  </van-button>
                  <input
                    type="file"
                    :ref="item.fafd01"
                    name="Files"
                    style="display: none;"
                    :accept="item.fafd03 != 4 ? 'image/png' : 'video/mp4'"
                    @change="clickUpload($event, item)"
                  />
                </div>
                <div v-else style="width:100%" class="flexLR">
                  <van-button
                    style="width:100%"
                    native-type="button"
                    size="mini"
                    icon="service-o"
                    @click.stop="
                      items = item;
                      play(item);
                    "
                  >
                    {{ item.fafd06 == "" ? "录音" : "播放录音" }}
                  </van-button>
                  <van-button
                    style="width:100%"
                    type="info"
                    @click="item.fafd06 = ''"
                    native-type="button"
                    icon="revoke"
                    size="mini"
                    v-if="item.fafd06 != ''"
                    >重录
                  </van-button>
                </div>
              </div>
            </template>
          </van-cell>
        </div>

        <audio ref="LogAudioPlayer" style="display:none;width:100%"></audio>

        <van-uploader
          ref="chooseFile"
          v-if="items.fafd03"
          :accept="items.fafd03 == 4 ? 'video/*' : 'image/*'"
          style="display:none;width:100%"
          v-model="fileList"
          :after-read="fileChange"
          :multiple="true"
        >
          <van-button
            size="mini"
            native-type="button"
            icon="plus"
            type="primary"
            >上传文件</van-button
          >
        </van-uploader>

        <van-calendar
          v-model="timeShow"
          @confirm="(val) => TimeonConfirm(val, items)"
        />

        <van-popup
          style="padding:20px"
          v-model="popupShow"
          round
          position="bottom"
          :style="{ height: '50%' }"
        >
          <div
            class="flexcolumn"
            style="height:100%;justify-content:space-between;align-items:start"
          >
            <van-checkbox-group v-model="userList">
              <van-checkbox
                style="margin:10px 0"
                v-for="(checkboxItem, index) in checkboxItemList"
                :key="checkboxItem.user01"
                :name="checkboxItem.user04"
                >{{ checkboxItem.user04 }}
              </van-checkbox>
            </van-checkbox-group>
            <div class="btn-group flexLR" style="width:100%;">
              <van-button
                style="width:49%"
                native-type="button"
                ref="regionResetBtn"
                round
                type="warning"
                size="small"
                @click="userList = []"
                class="btn-group-button"
              >
                重置</van-button
              >
              <van-button
                style="width:49%"
                native-type="button"
                @click="addUser"
                round
                type="info"
                size="small"
                class="btn-group-button"
                >确认
              </van-button>
            </div>
          </div>
        </van-popup>
      </van-form>
      <div class="audBtn" style="padding:25px 16px;">
        <template>
          <van-button
            round
            type="info"
            v-if="IsRejectData"
            :loading="httpLoading"
            loading-text="处理中..."
            @click.stop="newonSubmit(1)"
            >通过</van-button
          >
          <van-button
            round
            v-else
            type="info"
            style="width: 80%;"
            :loading="httpLoading"
            loading-text="处理中..."
            @click.stop="newonSubmit(1)"
            >通过</van-button
          >
        </template>
        <van-button
          v-if="IsRejectData"
          round
          type="danger"
          :loading="httpLoading"
          loading-text="处理中..."
          @click.stop="newonSubmit(2)"
          >驳回</van-button
        >
      </div>
    </div>
    <van-popup round v-model="show" style="width:80%;height:60%;padding:20px;">
      <div
        style="width:100%;height:100%;justify-content: space-between;"
        class="flexcolumn"
      >
        <div
          style="height:95%"
          v-if="
            GetFullFormTempList.filter(
              (el) => el.fmtp01 == AddFormApplyParams.tempId
            ).length
          "
        >
          <iframe
            type="application/pdf"
            :src="
              $store.state.baseUrl +
                GetFullFormTempList.filter(
                  (el) => el.fmtp01 == AddFormApplyParams.tempId
                )[0].fmtp05
            "
            width="100%"
            height="100%"
            frameborder="0"
          ></iframe>
        </div>
        <van-button
          style="width:100%;margin-top: 10px;"
          type="info"
          @click="AddFormApply"
          round
          >发布</van-button
        >
      </div>
    </van-popup>
    <Recorderx ref="Recorderx"></Recorderx>
    <van-popup
      v-model="show1"
      round
      position="bottom"
      :style="{ height: '30%' }"
    >
      <div v-if="isLY">
        <div class="wave w1"></div>
        <div class="wave w2"></div>
      </div>
      <div class="flex" style="height: 100%;width:100%;padding:40px">
        <div class="flexcolumn">
          <van-icon name="close" size="40" @click="closeLY" />
          重录
        </div>
        <div class="diyButton" @click="startLY">
          {{ isLY ? "录音中..." : "录音" }}
        </div>
        <div class="flexcolumn">
          <van-icon @click="endLY" name="passed" size="40" />
          完成
        </div>
      </div>
    </van-popup>
    <van-loading
      class="loading-bg"
      type="spinner"
      size="24px"
      v-show="httpLoading"
    />
  </div>
</template>

<script>
import {
  SubmitAudit,
  FindFormApplyDetail,
  GetNetDateTime,
  GetFullFormTemp,
  GetFormAuditFlowList,
  GetOneFormAuditFlow,
  GetOneFormTemp,
  GetProUsersList,
  AddSafeFormApply,
  IsReject,
} from "@/api";
import Recorderx from "@/components/Recorderx";
import Pdfh5 from "pdfh5";
import { async } from "q";

export default {
  name: "addSecurity",
  components: { Recorderx },
  data() {
    return {
      FindFormApplyDetailData: {
        FormApply: {
          famp06: "",
          famp07: "",
        },
        FormAuditRecord: [],
        FormApplySafeFields: [],
        FormAuditFlow: [],
      },
      list: [],
      GetFullFormTempList: ["杭州", "宁波", "温州", "嘉兴", "湖州"],
      showPicker: false,
      showPicker2: false,
      show: false,
      show1: false,
      isLY: false,
      AddFormApplyParams: {
        name: "",
        tempId: "",
        auditId: "",
        qid: "",
      },
      AuditFlowList: [],
      detailList: [],
      auditName: "",
      formTemplateName: "",
      formTemp: null,
      tempFields: [],
      timeShow: false,
      checkboxItemList: [],
      popupShow: false,
      userList: [],
      items: {},
      fileList: [],
      newDate: new Date().toLocaleDateString(),
      httpLoading: false,
      auditType: "",
      IsRejectData: null,
    };
  },
  computed: {},
  watch: {},
  methods: {
    IsReject(val) {
      let params = { id: val };
      IsReject({ params }).then((res) => {
        this.IsRejectData = res.Data;
      });
    },
    openUpdateFile(id) {
      this.$refs[id][0].click();
    },
    GetNetDateTime() {
      GetNetDateTime().then((res) => {
        this.newDate = res.Data;
      });
    },
    clickUpload(e, item) {
      item.fafd06 = e.target.files[0];
      this.items = item;
    },
    fileChange(file) {
      this.items.fafd06 = file.file;
    },
    addUser() {
      this.items.fafd06 = this.userList.toString().substr(1);
      this.userList = [];
      this.popupShow = false;
    },
    GetProUsersList() {
      let params = {
        seachword: "",
        id: this.$store.state.pid,
        cid: this.$store.state.qid,
        bid: "null",
        pageIndex: 1,
        pageSize: 999999,
      };
      GetProUsersList({ params }).then((res) => {
        this.checkboxItemList = res.Data.list;
      });
    },
    formatDate(date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    },
    TimeonConfirm(date, item) {
      item.fafd06 = this.formatDate(date);
      this.timeShow = false;
    },
    GetFormAuditFlowList() {
      let params = {
        xid: this.$store.state.pid,
        searchword: "",
        pageIndex: 1,
        pageSize: 99999,
        type: 2,
      };
      GetFormAuditFlowList({ params }).then((res) => {
        this.AuditFlowList = res.Data.list;
        this.AddFormApplyParams.qid = this.$store.state.qid;
        if (res.Data.list.length) {
          this.AddFormApplyParams.auditId = this.AuditFlowList[0].fafl01;
          this.auditName = this.AuditFlowList[0].fafl03;
          this.GetOneFormAuditFlow(this.AddFormApplyParams.auditId);
        }
      });
    },
    GetOneFormAuditFlow(id) {
      let params = { id };
      GetOneFormAuditFlow({ params }).then((res) => {
        this.detailList = res.Data.detailList;
      });
    },
    AddFormApply() {
      let fd = new FormData();
      for (var key in this.AddFormApplyParams) {
        fd.append(key, this.AddFormApplyParams[key]);
      }
      this.tempFields.forEach((ele) => {
        fd.append(ele.fmfd06, ele.fafd06);
      });
      fd.append("famp15", 2);
      AddSafeFormApply(fd).then((res) => {
        if (res.Code) {
          this.$Toast("添加成功");
          Object.assign(this.$data, this.$options.data());
        }
      });
    },
    GetFullFormTemp() {
      GetFullFormTemp({
        params: {
          xid: this.$store.state.pid,
          type: 2,
        },
      }).then((res) => {
        this.GetFullFormTempList = res.Data;
        this.formTemp = res.Data[0];
        this.AddFormApplyParams.tempId = res.Data[0].fmtp01;
      });
    },
    endLY() {
      this.$refs.Recorderx.recStop().then((blob) => {
        this.items.fafd06 = blob || "";
        this.isLY = false;
        this.show1 = false;
      });
    },
    play() {
      let _this = this;
      if (this.items.fafd06 == "") {
        this.show1 = true;
      } else {
        var audio = this.$refs.LogAudioPlayer;
        audio.controls = true;
        if (!(audio.ended || audio.paused)) {
          audio.pause();
        }
        audio.onerror = function(e) {
          _this.$Toast.fail("播放失败");
        };
        audio.src = (window.URL || webkitURL).createObjectURL(
          this.items.fafd06
        );
        audio.play();
      }
    },
    startLY() {
      this.$refs.Recorderx.recOpen().then((res) => {
        this.isLY = res;
      });
    },
    closeLY() {
      this.isLY = false;
      this.$refs.Recorderx.recClose();
    },
    onConfirm(value) {
      this.formTemplateName = value.fmtp03;
      this.AddFormApplyParams.tempId = value.fmtp01;
      this.GetOneFormTemp(value.fmtp01);
      this.showPicker = false;
    },
    onConfirm2(value) {
      this.GetOneFormAuditFlow(value.fafl01);
    },
    newonSubmit(type) {
      this.auditType = type;
      this.$refs.submit.submit();
    },
    onSubmit(errorInfo) {
      this.SubmitAudit();
    },
    SubmitAudit() {
      this.$Dialog
        .confirm({
          title: "提示",
          message: this.auditType == 1 ? "确定提交？" : "确定驳回？",
        })
        .then(() => {
          this.httpLoading = true;
          let fd = new FormData();
          fd.append("qid", this.$store.state.qid);
          fd.append("id", this.$route.query.id);
          fd.append("type", this.auditType);
          fd.append("newtype", 2);
          GetNetDateTime().then((res) => {
            this.tempFields.forEach((ele) => {
              if (ele.fafd03 == "6") {
                ele.fafd06 = res.Data;
              }
              if (ele.fafd03 == "2") {
                ele.fafd06 = this.checkboxItemList.filter(
                  (k) => k.user01 == this.$store.state.uid
                )[0].user04;
              }
              fd.append(ele.fafd05, ele.fafd06);
            });
            SubmitAudit(fd).then((res) => {
              if (res.Code) {
                this.$Toast("处理成功");
                this.$router.go(-1);
              } else {
                this.$Toast(res.Message);
              }
              this.httpLoading = false;
            });
          });
        })
        .catch();
    },
    updateData() {
      this.getData();
      this.$emit("updateLoading", "消息");
    },
    async consentBtn(v) {
      let res = await HandleMsg({
        id: v.Id,
        type: v.Type,
        state: 1,
      });
      this.$CodeMessage(res);
      this.getData();
    },
    async refuseBtn(v) {
      let res = await HandleMsg({
        id: v.Id,
        type: v.Type,
        state: 2,
      });
      this.$CodeMessage(res);
      this.getData();
    },
    async deleteBtn(v) {
      let res = await DelMsg({
        id: v.Id,
        type: v.Type,
      });
      this.$CodeMessage(res, "操作成功");
      this.getData();
    },
    async getData() {
      let res = await GetMessage();
      if (res.Code > 0) this.list = res.Data;
    },
    FindFormApplyDetail() {
      this.IsReject(this.$route.query.id);
      FindFormApplyDetail({
        params: {
          id: this.$route.query.id,
          type: 4,
        },
      }).then((res) => {
        this.FindFormApplyDetailData = res.Data;
        this.pdfh5 = new Pdfh5("#PDFIframe", {
          pdfurl:
            this.$store.state.baseUrl +
            this.FindFormApplyDetailData.FormApply.famp06,
        });
        this.tempFields = res.Data.FormApplySafeFields.filter((ele) =>
          ele.fafd04.split(",").includes(this.$store.state.uid.toString())
        );
        this.tempFields.forEach((ele) =>
          ["3", "4", "5"].includes(ele.fafd03)
            ? (ele.fafd06 = [])
            : (ele.fafd06 = "")
        );

        let that = this;
        new Promise(async function(resolve, reject) {
          let params = {
            seachword: "",
            id: that.$store.state.pid,
            cid: that.$store.state.qid,
            bid: "null",
            pageIndex: 1,
            pageSize: 999999,
          };
          let res = await GetProUsersList({ params });
          resolve(res.Data.list);
        }).then(function(newuserList) {
          that.tempFields.forEach((ele) =>
            ["3", "4", "5"].includes(ele.fafd03)
              ? (ele.fafd06 = [])
              : ele.fafd03 == "2"
              ? (ele.fafd06 = newuserList.filter(
                  (k) => k.user01 == that.$store.state.uid
                )[0].user04)
              : ele.fafd03 == 6
              ? (ele.fafd06 = that.newDate)
              : (ele.fafd06 = "")
          );
        });
      });
    },
    GetOneFormTemp() {
      let params = {
        id: this.$route.query.id,
        type: 4,
      };
      GetOneFormTemp({ params }).then((res) => {
        //   过滤发起人需要填写的
        this.tempFields = res.Data.tempFields.filter((ele) => ele.fafd04 == 2);
        this.tempFields.forEach((ele) =>
          ["3", "4", "5"].includes(ele.fafd03)
            ? (ele.fafd06 = [])
            : (ele.fafd06 = "")
        );
      });
    },
  },
  created() {
    this.GetNetDateTime();
    this.GetProUsersList();
    this.FindFormApplyDetail();
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.SecurityInfo {
  margin: 0 auto;
  height: 100%;

  .wave {
    position: absolute;
    top: calc((100% - 60px) / 2);
    left: calc((100% - 60px) / 2);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-attachment: fixed;
    background-position: center center;
  }

  .shlcDIV {
    border: 1px solid rgb(236, 236, 236);
    width: 200px;
    margin: 12px;
    height: 200px;
    border-radius: 10px;
  }

  .w1 {
    z-index: 2;
    border: 6px solid red;
    background-size: auto 106%;
    animation: wave 1s infinite;
  }

  .w2 {
    z-index: 3;
    border: 6px solid red;
    background-size: auto 106%;
    animation: wave 1s 0.2s infinite;
  }

  .diyButton {
    z-index: 9;
    width: 210px;
    text-align: center;
    line-height: 210px;
    color: white;
    font-size: 30px;
    letter-spacing: 4px;
    box-shadow: 0 0 5px 5px red;
    background-color: red;
    height: 210px;
    margin: auto auto;
    border-radius: 50%;
  }

  @keyframes wave {
    0% {
      top: calc((100% - 60px) / 2);
      left: calc((100% - 60px) / 2);
      width: 60px;
      height: 60px;
    }

    100% {
      top: calc((100% - 250px) / 2);
      left: calc((100% - 250px) / 2);
      width: 250px;
      height: 250px;
      opacity: 0.4;
    }
  }

  .loading-bg {
    position: fixed;
    width: 60px;
    height: 60px;
    margin: -30px 0 0 -30px;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    top: 50%;
    left: 50%;
    text-align: center;
    line-height: 60px;
    border-radius: 5px;
  }
  .audBtn {
    display: flex;
    justify-content: space-around;
    .van-button {
      border-radius: 5px;
      width: 40%;
    }
  }
}
</style>
